define(['app', 'elementViewed'], function(app, elementViewed) {

  const stickyCheckoutButton = function() {

    const component = {};

    const _config = {
      selectors: {
        button: '[data-js-element=stickyButton]',
        container: '[data-js-element=stickyButtonContainer]'
      }
    };

    const _init = function(element) {
      component.element = element;
      component.container = document.querySelector(component.config.selectors.container);
      component.bind();
      return component;
    };

    const _bind = function() {
      component.elementViewed(component.element, component.stickyCheckoutButtonViewed);
      component.element.addEventListener('click', component.stickyCheckoutButtonClicked);
      component.scrollListener();
    };

    const _stickyCheckoutButtonViewed = function() {
      app.publish('tracking/record', 'Widget Track', 'Viewed', component.element.getAttribute("data-block-name"), component.element.getAttribute("data-widget-id"));
    };

    const _stickyCheckoutButtonClicked = function() {
      app.publish('tracking/record', 'Widget Track', 'Clicked', component.element.getAttribute("data-block-name"), component.element.getAttribute("data-widget-id"));
    };



    const _scrollListener = function () {


      var isInViewport = function (elem) {
        var bounding = elem.getBoundingClientRect();
        return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      };

      window.addEventListener("scroll",function(){

        var button = document.querySelector('[data-js-element="basketButton"]');

        window.addEventListener('scroll', function (event) {
          if(window.innerWidth > 900) {
            component.container.style.display = "none";
          }
          else if (isInViewport(button)) {
            component.container.style.display = "none";
          }
          else  {
            component.container.style.display = "block";
          }
        }, false);

      },{
        capture: true,
        passive: true
      });
    }

    component.config = _config;
    component.elementViewed = elementViewed;
    component.init = _init;
    component.bind = _bind;
    component.stickyCheckoutButtonViewed = _stickyCheckoutButtonViewed;
    component.stickyCheckoutButtonClicked = _stickyCheckoutButtonClicked;
    component.scrollListener = _scrollListener;
    return component;
  };

  return stickyCheckoutButton;
});
